<template>
  <div class="container overview">
    <div class="menu-wrapper">
      <Menu />
    </div>
    <router-view />
  </div>
</template>

<script>
import Menu from '@/components/Menu'

export default {
  components: {
    Menu
  }
}
</script>

<style scoped lang="scss">
.overview {
  @include flex(row);
  align-items: center;
  justify-content: center;
  position: relative;
  .menu-wrapper {
    position: absolute;
    top: 0;
  }
}
</style>

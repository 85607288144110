<template>
  <div class="ov-menu">
    <div class="box">
      <div
        class="item"
        :class="currType == item.type ? 'active' : ''"
        v-for="(item, index) in items"
        :key="index"
        @click="handleMenu(item)"
      >
        <span>{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data() {
    return {
      currType: '',
      items: [
        { label: '基础资源', type: 'br' },
        { label: '发展历程', type: 'dh' },
        { label: '业态规划', type: 'bfp' },
        { label: '发展理念', type: 'dc' }
      ]
    }
  },
  computed: {
    ...mapGetters(['headerType', 'menuType'])
  },
  watch: {
    menuType: {
      handler(val) {
        this.currType = val
      },
      immediate: true
    }
  },
  methods: {
    handleMenu({ label, type }) {
      console.log('二级菜单:', label, type)
        this.$eventBus.$emit('iw-dc-legend',type)
      if (type === this.currType) return
      this.$store.dispatch('setMenuType', type)
    }
  }
}
</script>

<style scoped lang="scss">
.ov-menu {
  @include flex();
  justify-content: center;
  // height: 90px;
  padding: 10px 0 30px 0;
}
.box {
  @include flex();
  pointer-events: all;
}
.item {
  background: url('/images/components/ov/menu-u.png') no-repeat;
  background-size: 100% 100%;
  font-size: 24px;
  padding: 12px 35px;
  margin-right: 5px;
  cursor: pointer;
  background-color: rgba($color: #1b2f37, $alpha: 0.8);
}
.active {
  background: url('/images/components/ov/menu-s.png') no-repeat;
  background-size: 100% 100%;
  font-weight: bold;
  background-color: rgba($color: #1b2f37, $alpha: 0.8);
}
.item:last-child {
  margin-right: 0;
}
</style>
